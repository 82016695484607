import * as React from "react"
import { Link } from "gatsby"
import { Button } from "../components/button/button"
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Layout from "../components/layout/layout";


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`

const ButtonWrapper = styled.div`
display: flex;
justify-content: space-around;
max-width: 400;
margin: 0 auto;
`

const noUnderline = css`
  text-decoration: none;
  padding: 1rem;
`
const NotFoundPage = () => {
  return (
      <Layout pageTitle='Page Not Found' showNav={true}>
        <Container>
          <h1>Ope! We couldn't find that page.</h1>
          <p>Sorry, we couldn’t find what you were looking for. 🙃</p>
          
          <ButtonWrapper>
            <Link to="/" css={noUnderline}>
              <Button>Go Home</Button>
            </Link>
            <Link to="/blog" css={noUnderline}>
              <Button>Blog Posts</Button>
            </Link>
          </ButtonWrapper>
        </Container>
        
      </Layout>

  )
}

export default NotFoundPage
